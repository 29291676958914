@import url('https://fonts.googleapis.com/css?family=Rubik|Montserrat&display=swap');

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  color: #313131;
}

* {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
}

code, pre, code span {
  font-family: Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
  font-size: 0.8rem;
}

.toast {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 5px rgba(0,0,0,0.075);
  padding: 16px 24px;
  border: none;
}

.Toastify__toast--error {
  background: #FF3F3F;
}

.progress {
  display: none;
}