@font-face {
    font-family: 'Enhanced';
    font-display: swap;  
	  unicode-range: U+0020-U+007D;
    src: local('Enhanced'),
    		url('jaapokkisubtract-regular.woff') format('woff');
            font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

